<template>
	<div>
		<!-- タイトル -->
		<PageTitle title-name="レッスン一覧" />
		<VmBox>
			<template #header>
				<v-row no-gutters>
					<VmButton
						type="primary"
						@click="openAddDialog"
						text="レッスンを追加する"
						:style="{
							'margin-right': '20px'
						}"
					/>
					<CsvUploadArea type="LESSON" @reload="reload" />
					<v-spacer />
					<VmButton
						:disabled="!selectedLessons.length"
						class="bulkEditButton"
						type="secondary"
						@click="openBulkEditDialog"
						text="選択レッスン一括編集"
					/>
					<VmButton
						:disabled="!selectedLessons.length"
						type="dangerous"
						@click="openDeleteDialog"
						text="選択レッスン一括削除"
					/>
					<VueJsonToCsv :json-data="downloadedCSVData" :csv-title="computedCSVTitle">
						<v-btn class="csv-button"> csvダウンロード </v-btn>
					</VueJsonToCsv>
				</v-row>
			</template>
			<template #content>
				<!-- 月変更できるところ -->
				<v-row>
					<SelectMonth
						v-model="selectedMonth"
						@fetchData="setUpLessonData"
						@reload="reload"
						:disabled="isDisabledMonthSelect"
					/>
					<v-checkbox v-model="isShowFromToday" label="今日以降" class="fromTodayButton"></v-checkbox>
					<v-text-field
						outlined
						background-color="white"
						dense
						v-model="globalSearchWord"
						label="表示月内で全体検索"
						@keyup.enter="searchWithKeyword"
						:style="{
							'margin-left': '20px'
						}"
					></v-text-field>
					<VmButton
						type="primary"
						@click="searchWithKeyword"
						text="検索"
						width="80px"
						:style="{ 'margin-left': '4px' }"
					/>
				</v-row>
				<div class="tableFunctionArea">
					<div class="expandHeader">
						<VmButton
							type="primary"
							@click="resetSort"
							:disabled="!isUnderSorting"
							text="検索条件リセット"
							:style="{
								'margin-right': '20px'
							}"
						/>
						<VmToggle v-model="isExpandedHeader" label="全てのヘッダー" on-label="表示" off-label="非表示" />
					</div>
					<div class="pagination" v-if="totalPages">
						<button v-if="!isFirstPage" @click="prevPage">
							<v-icon small> mdi-chevron-left </v-icon>
						</button>
						<div>{{ currentPage }} / {{ totalPages }} page</div>
						<button v-if="!isLastPage" @click="nextPage">
							<v-icon small> mdi-chevron-right </v-icon>
						</button>
					</div>
				</div>
				<v-data-table
					v-model="selectedLessons"
					show-select
					item-key="lessonId"
					:headers="headers"
					:items="lessonArray"
					:search="localSearchWord"
					multi-sort
					class="elevation-1 overflow-y-auto"
					disable-pagination
					no-data-text="レッスン記録がありません"
					hide-default-footer
					:loading="loading"
					fixed-header
					:height="lessonArray.length > 15 ? 48 * 15 - 1 : undefined"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
				>
					<template v-slot:top>
						<v-row class="filter-area">
							<v-dialog v-model="showEditDialog" max-width="500px">
								<AdminAddFormCard2
									@closeAction="closeEditDialog"
									@addAction="save"
									:editedItem="filteredEditItem"
									formTitle="レッスン内容編集"
									buttonTitle="edit"
									@changeFile="changeFile($event)"
									:loading="loading"
								/>
							</v-dialog>
						</v-row>
					</template>
					<template slot="body.prepend">
						<tr class="sticky-table-header">
							<td>
								<v-icon>mdi-filter-variant</v-icon>
							</td>
							<td>
								<v-text-field v-model="filterItems.lessonId" type="number" label="レッスンID"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.teacherName" type="text" label="講師名"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.teacherSystemId" type="number" label="講師ID"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.studentName" type="text" label="生徒"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.studentSystemId" type="number" label="生徒ID"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.startTime" type="date" label="開始"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.endTime" type="date" label="終了"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.lessonTime" type="number" label="レッスン時間"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.course" type="text" label="コース"></v-text-field>
							</td>
							<td>
								<v-select
									:items="statusItems"
									v-model="filterItems.isFilterStatus"
									label="ステータス"
									outline
									item-text="text"
									item-value="value"
								></v-select>
							</td>
							<td>
								<v-text-field v-model="filterItems.memoForTeacher" type="text" label="講師メモ"></v-text-field>
							</td>
							<td>
								<v-select
									:items="booleanItems"
									v-model="filterItems.isFirstLesson"
									label="初回レッスン"
									outline
									item-text="text"
									item-value="value"
								></v-select>
							</td>
							<td>
								<v-text-field v-model="filterItems.invoiceForStudent" type="number" label="請求金額"></v-text-field>
							</td>
							<td>
								<v-text-field v-model="filterItems.rewardForTeacher" type="number" label="報酬金額"></v-text-field>
							</td>
							<td>
								<v-select
									:items="booleanItems"
									v-model="filterItems.isChecked"
									label="チェック済"
									outline
									item-text="text"
									item-value="value"
								></v-select>
							</td>
							<td>
								<v-select
									:items="booleanItems"
									v-model="filterItems.isRequestedForCancel"
									label="削除依頼済"
									outline
									item-text="text"
									item-value="value"
								></v-select>
							</td>
							<template v-if="isExpandedHeader">
								<td>
									<v-select
										:items="booleanItems"
										v-model="filterItems.isReminded2Hours"
										label="リマインド2時間前"
										outline
										item-text="text"
										item-value="value"
									></v-select>
								</td>
								<td>
									<v-select
										:items="booleanItems"
										v-model="filterItems.isReminded24Hours"
										label="リマインド24時間前"
										outline
										item-text="text"
										item-value="value"
									></v-select>
								</td>
								<td>
									<v-select
										:items="booleanItems"
										v-model="filterItems.isReminded72Hours"
										label="リマインド72時間前"
										outline
										item-text="text"
										item-value="value"
									></v-select>
								</td>
								<td>
									<v-select
										:items="booleanItems"
										v-model="filterItems.isFeedback"
										label="フィードバック"
										outline
										item-text="text"
										item-value="value"
									></v-select>
								</td>
								<td>
									<v-text-field v-model="filterItems.lessonReport" type="text" label="レッスン内容報告"></v-text-field>
								</td>
							</template>
							<td></td>
						</tr>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
						<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
					</template>
					<template v-slot:[`item.isFeedback`]="{ item }">
						<span>{{ item.isFeedback }}</span>
					</template>
				</v-data-table>
			</template>
		</VmBox>
		<v-dialog v-model="showAddDialog" v-if="showAddDialog" max-width="500px">
			<VmAdminLessonAddCard
				v-model="showAddDialog"
				v-if="showAddDialog"
				@complete="addComplete"
				@close="closeAddDialog"
			/>
		</v-dialog>
		<v-dialog v-model="showDeleteDialog" v-if="showDeleteDialog" max-width="500px">
			<AdminDeleteFormCard
				@closeDeleteAction="closeDeleteDialog"
				@deleteItemConfirmAction="deleteAllSelectedLessons"
				formTitle="レッスン一括削除"
				:content="bulkUpdatedLessonText"
			/>
		</v-dialog>
		<v-dialog v-model="showBulkEditDialog" v-if="showBulkEditDialog" max-width="500px">
			<AdminBulkUpdateLessonFormCard
				@close="closeBulkEditDialog"
				@handleAction="bulkEdit"
				formTitle="レッスン一括編集"
				:content="bulkUpdatedLessonText"
			/>
		</v-dialog>
	</div>
</template>

<script>
import PageTitle from '../Atoms/PageTitle'
import CsvUploadArea from '../Molecules/CsvUploadArea'
import SelectMonth from '../Molecules/SelectMonth'
import VmButton from '../Atoms/VmButton'
import { VmAdminLessonAddCard } from '../domains/admin'
import AdminDeleteFormCard from '../Organisms/AdminDeleteFormCard'
import { computeLessonTime } from '../../util/computeLessonTime'
import VmBox from '../Atoms/VmBox'
import VmToggle from '../Atoms/VmToggle.vue'
import { mapGetters, mapActions } from 'vuex'
import AdminBulkUpdateLessonFormCard from '../Organisms/AdminBulkUpdateLessonFormCard'
import AdminAddFormCard2 from '../Organisms/AdminAddFormCard2'

export default {
	name: 'AdminLessonList',
	inject: ['reloadRouter'],
	components: {
		PageTitle,
		SelectMonth,
		CsvUploadArea,
		VmButton,
		VmAdminLessonAddCard,
		AdminDeleteFormCard,
		VmBox,
		VmToggle,
		AdminBulkUpdateLessonFormCard,
		AdminAddFormCard2
	},
	data: () => ({
		filterItems: {
			lessonId: '',
			teacherName: '',
			teacherSystemId: '',
			studentName: '',
			studentSystemId: '',
			startTime: '',
			endTime: '',
			lessonTime: '',
			course: '',
			status: '',
			memoForTeacher: '',
			isFirstLesson: '',
			invoiceForStudent: '',
			rewardForTeacher: '',
			isChecked: '',
			isRequestedForCancel: '',
			lessonReport: ''
		},
		selectedMonth: null,
		selectedLessons: [],
		statusItems: [
			{ text: '', value: null },
			{ text: '未', value: 0 },
			{ text: '完了', value: 1 },
			{ text: 'キャンセル', value: 2 }
		],
		booleanItems: [
			{ text: '', value: null },
			{ text: 'true', value: true },
			{ text: 'false', value: false }
		],
		lessonArray: [],
		isFilterStatus: null,
		isShowFromToday: false,
		globalSearchWord: '',
		localSearchWord: '',
		showEditDialog: false,
		dialogDelete: false,
		selectedLessonId: 0,
		addExplanation:
			'statusについて\n 「未」の場合は0、「完了」の場合は1、「キャンセル」の場合は2を入力してください。（全て半角数字）',
		editedItem: {
			lessonId: {
				value: '',
				type: 'display',
				required: false,
				label: 'レッスンID'
			},
			teacherName: {
				value: '',
				type: 'display',
				required: false,
				label: '講師名'
			},
			teacherSystemId: {
				value: '',
				type: 'display',
				required: false,
				label: '講師ID'
			},
			studentName: {
				value: '',
				type: 'display',
				required: false,
				label: '生徒名'
			},
			studentSystemId: {
				value: '',
				type: 'display',
				required: false,
				label: '生徒ID'
			},
			startTime: {
				value: '',
				type: 'dateTime',
				required: true,
				label: '開始時間'
			},
			endTime: {
				value: '',
				type: 'dateTime',
				required: true,
				label: '終了時間'
			},
			lessonTime: {
				value: '',
				type: 'number',
				required: true,
				label: 'レッスン時間'
			},
			course: {
				value: '',
				type: 'select',
				required: true,
				label: 'コース',
				options: [
					{ text: '会話', value: 'CONVERSATION' },
					{ text: 'プライベート', value: 'PRIVATE' },
					{ text: 'テキスト', value: 'TEXT' }
				]
			},
			status: {
				value: '',
				type: 'select',
				required: true,
				label: 'ステータス',
				options: [
					{ text: '未', value: 0 },
					{ text: '完了', value: 1 },
					{ text: 'キャンセル', value: 2 }
				]
			},
			memoForTeacher: {
				value: '',
				type: 'textarea',
				required: false,
				label: '講師メモ'
			},
			isFirstLesson: {
				value: '',
				type: 'boolean',
				required: false,
				label: '初回レッスン',
				default: false
			},
			invoiceForStudent: {
				value: '',
				type: 'number',
				required: false,
				label: '請求金額'
			},
			rewardForTeacher: {
				value: '',
				type: 'number',
				required: false,
				label: '報酬金額'
			},
			isChecked: {
				value: '',
				type: 'boolean',
				required: false,
				label: 'チェック済',
				default: false
			},
			isRequestedForCancel: {
				value: '',
				type: 'boolean',
				required: false,
				label: '削除依頼済',
				default: false
			},
			isReminded2Hours: {
				value: '',
				type: 'boolean',
				required: false,
				label: 'リマインド2時間前',
				default: false
			},
			isReminded24Hours: {
				value: '',
				type: 'boolean',
				required: false,
				label: 'リマインド24時間前',
				default: false
			},
			isReminded72Hours: {
				value: '',
				type: 'boolean',
				required: false,
				label: 'リマインド72時間前',
				default: false
			},
			isFeedback: {
				value: '',
				type: 'boolean',
				required: false,
				label: 'フィードバック',
				default: false
			},
			lessonReport: {
				value: '',
				type: 'textarea',
				required: false,
				label: 'レッスン内容報告'
			}
		},
		downloadedCSVData: [],
		showAddDialog: false,
		showDeleteDialog: false,
		isDisabledMonthSelect: false,
		isChecked: false,
		isRequestedForCancel: null,
		currentPage: 1,
		lessonsPerPage: 50,
		isExpandedHeader: false,
		showBulkEditDialog: false,
		sortBy: [], // ソートの基準となる列
		sortDesc: false // ソートの方向（昇順または降順）
	}),
	watch: {
		lessons(newVal, oldVal) {
			// lessonsが更新されたら、makeLessonArrayを実行
			if (newVal !== oldVal) {
				this.makeLessonArray(newVal)
			}
		}
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			loading: 'adminLesson/loading',
			lessons: 'adminLesson/lessons',
			totalPages: 'adminLesson/totalPages'
		}),
		headers() {
			const baseHeaders = [
				{
					text: 'lessonId',
					align: 'start',
					value: 'lessonId',
					filter: (value) => {
						return (
							!this.filterItems.lessonId ||
							String(value).toLowerCase().includes(this.filterItems.lessonId.toLowerCase())
						)
					}
				},
				{
					text: '講師',
					value: 'teacherName',
					filter: (value) => {
						return (
							!this.filterItems.teacherName ||
							String(value).toLowerCase().includes(this.filterItems.teacherName.toLowerCase())
						)
					}
				},
				{
					text: '講師ID',
					value: 'teacherSystemId',
					filter: (value) => {
						return (
							!this.filterItems.teacherSystemId ||
							String(value).toLowerCase().includes(this.filterItems.teacherSystemId.toLowerCase())
						)
					}
				},
				{
					text: '生徒',
					value: 'studentName',
					filter: (value) => {
						return (
							!this.filterItems.studentName ||
							String(value).toLowerCase().includes(this.filterItems.studentName.toLowerCase())
						)
					}
				},
				{
					text: '生徒ID',
					value: 'studentSystemId',
					filter: (value) => {
						return (
							!this.filterItems.studentSystemId ||
							String(value).toLowerCase().includes(this.filterItems.studentSystemId.toLowerCase())
						)
					}
				},
				{
					text: '開始時間',
					value: 'startTime',
					filter: (value) => {
						if (this.filterItems.startTime) {
							return String(value).toLowerCase().includes(this.filterItems.startTime.toLowerCase())
						}
						if (this.isShowFromToday) {
							return value >= this.$moment().format('YYYY-MM-DD HH:mm')
						}
						return true
					}
				},
				{ text: '終了時間', value: 'endTime' },
				{
					text: 'レッスン時間（分）',
					value: 'lessonTime',
					filter: (value) => {
						return (
							!this.filterItems.lessonTime ||
							String(value).toLowerCase().includes(this.filterItems.lessonTime.toLowerCase())
						)
					}
				},
				{
					text: 'コース',
					value: 'course',
					filter: (value) => {
						return (
							!this.filterItems.course || String(value).toLowerCase().includes(this.filterItems.course.toLowerCase())
						)
					}
				},
				{
					text: 'レッスンステータス',
					value: 'status',
					filter: (value) => {
						if (!this.filterItems.course) return true
						if (this.filterItems.isFilterStatus === null || this.filterItems.isFilterStatus === '') {
							return true
						}
						return value === this.filterItems.isFilterStatus
					}
				},
				{
					text: '講師メモ',
					value: 'memoForTeacher',
					filter: (value) => {
						return (
							!this.filterItems.memoForTeacher ||
							String(value).toLowerCase().includes(this.filterItems.memoForTeacher.toLowerCase())
						)
					}
				},
				{
					text: '初回レッスン',
					value: 'isFirstLesson',
					filter: (value) => {
						return !this.filterItems.isFirstLesson || this.filterItems.isFirstLesson === value
					}
				},
				{
					text: '請求金額',
					value: 'invoiceForStudent',
					filter: (value) => {
						return (
							!this.filterItems.invoiceForStudent ||
							String(value).toLowerCase().includes(this.filterItems.invoiceForStudent.toLowerCase())
						)
					}
				},
				{
					text: '報酬金額',
					value: 'rewardForTeacher',
					filter: (value) => {
						return (
							!this.filterItems.rewardForTeacher ||
							String(value).toLowerCase().includes(this.filterItems.rewardForTeacher.toLowerCase())
						)
					}
				},
				{
					text: 'チェック済み',
					value: 'isChecked',
					filter: (value) => {
						return !this.filterItems.isChecked || this.filterItems.isChecked === value
					}
				},
				{
					text: '削除依頼済み',
					value: 'isRequestedForCancel',
					filter: (value) => {
						return !this.filterItems.isRequestedForCancel || this.filterItems.isRequestedForCancel === value
					}
				},
				{ text: 'Actions', value: 'actions', sortable: false }
			]

			if (this.isExpandedHeader) {
				const addedHeaders = [
					{
						text: 'リマインド2時間前',
						value: 'isReminded2Hours',
						filter: (value) => {
							return !this.filterItems.isReminded2Hours || this.filterItems.isReminded2Hours === value
						}
					},
					{
						text: 'リマインド24時間前',
						value: 'isReminded24Hours',
						filter: (value) => {
							return !this.filterItems.isReminded24Hours || this.filterItems.isReminded24Hours === value
						}
					},
					{
						text: 'リマインド72時間前',
						value: 'isReminded72Hours',
						filter: (value) => {
							return !this.filterItems.isReminded72Hours || this.filterItems.isReminded72Hours === value
						}
					},
					{
						text: 'フィードバック',
						value: 'isFeedback',
						filter: (value) => {
							return !this.filterItems.isFeedback || this.filterItems.isFeedback === value
						}
					},
					{
						text: 'レッスン内容報告',
						value: 'lessonReport',
						filter: (value) => {
							return (
								!this.filterItems.lessonReport ||
								String(value).toLowerCase().includes(this.filterItems.lessonReport.toLowerCase())
							)
						}
					}
				]
				baseHeaders.splice(baseHeaders.length - 1, 0, ...addedHeaders)
			}
			return baseHeaders
		},
		computedCSVTitle() {
			return this.$moment(this.selectedMonth).format('YYYY_MM_') + 'レッスン一覧'
		},
		bulkUpdatedLessonText() {
			let bulkUpdatedLessonText = ''
			for (let selectedLesson of this.selectedLessons) {
				bulkUpdatedLessonText =
					bulkUpdatedLessonText +
					`id: ${selectedLesson.lessonId}\n講師名: ${selectedLesson.teacherName}\n生徒名: ${selectedLesson.studentName}\n開始時刻: ${selectedLesson.startTime}\n終了時刻: ${selectedLesson.endTime}\nコース: ${selectedLesson.course}\n生徒への請求金額: ${selectedLesson.invoiceForStudent}\n講師への報酬金額: ${selectedLesson.rewardForTeacher}\n---------------------\n\n`
			}
			return bulkUpdatedLessonText
		},
		paginatedLessons() {
			const start = (this.currentPage - 1) * this.lessonsPerPage
			const end = start + this.lessonsPerPage
			return this.lessonArray.slice(start, end)
		},
		isFirstPage() {
			return this.currentPage === 1
		},
		isLastPage() {
			return this.currentPage === this.totalPages
		},
		isUnderSorting() {
			// sortByが設定されているか、sortDescがtrueかどうかのチェックを保持
			const isSorting = this.sortBy.length > 0 || Boolean(this.sortDesc)

			// filterItemsのいずれかのvalueが空文字ではない場合にtrueを返す
			const isFiltering = Object.values(this.filterItems).some((value) => value !== '')

			return isSorting || isFiltering
		},
		filteredEditItem() {
			// this.editedItemからpasswordを除外した新しいオブジェクトを返す
			const { password, ...filteredItem } = this.editedItem
			return filteredItem
		}
	},
	props: [],
	async created() {
		this.selectedMonth = this.$moment() //今日の日付を入れておく
		this.reload()
	},
	mounted() {},
	methods: {
		...mapActions({
			fetchLessons: 'adminLesson/fetchLessons',
			bulkEditLessons: 'adminLesson/bulkEditLessons'
		}),
		async reload() {
			this.isDisabledMonthSelect = true
			this.lessonArray = []
			this.selectedLessons = []
			this.resetPage()

			try {
				await this.setUpLessonData()
			} catch (error) {
				this.isDisabledMonthSelect = false
				console.log('error', error)
			}
			this.isDisabledMonthSelect = false
		},
		async setUpLessonData() {
			this.lessonArray = []
			await this.fetchLessons({
				accessToken: this.userInfo.accessToken,
				selectedMonth: this.selectedMonth,
				currentPage: this.currentPage,
				searchWord: this.globalSearchWord
			})
		},
		makeLessonArray(lessons) {
			this.lessonArray = []
			this.downloadedCSVData = []

			for (let lesson of lessons) {
				const lessonData = {
					lessonId: Number(lesson.id),
					teacherName: lesson['Teacher.teacherName'],
					teacherSystemId: lesson['Teacher.id'],
					studentName: lesson['Student.name'],
					studentEmail: lesson['Student.email'],
					studentSystemId: lesson['Student.id'],
					studentVollmondNumber: lesson['Student.studentIdNum'],
					startTime: this.$moment(lesson.startTime).format('YYYY-MM-DD HH:mm'),
					endTime: this.$moment(lesson.endTime).format('YYYY-MM-DD HH:mm'),
					lessonTime: computeLessonTime(lesson.startTime, lesson.endTime),
					course: lesson['LessonCourse.courseName'],
					status: lesson.status,
					lessonReport: lesson.lessonReport,
					memoForTeacher: lesson.memoForTeacher,
					isFirstLesson: Boolean(lesson.isFirstLesson),
					invoiceForStudent: lesson.invoiceForStudent,
					rewardForTeacher: lesson.rewardForTeacher,
					isReminded2Hours: Boolean(lesson.isReminded2Hours),
					isReminded24Hours: Boolean(lesson.isReminded24Hours),
					isReminded72Hours: Boolean(lesson.isReminded72Hours),
					isChecked: Boolean(lesson.isChecked),
					isRequestedForCancel: Boolean(lesson.isRequestedForCancel),
					isFeedback: Boolean(lesson.isFeedback)
				}
				this.lessonArray.push(lessonData)
				// CSV出力用に入れておく
				this.downloadedCSVData.push(lessonData)
			}
		},
		editItem(item) {
			this.selectedLessonId = item.lessonId
			this.mapItemToEditedItem(item)
			this.showEditDialog = true
		},
		resetEditedForm() {
			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key)) {
					this.editedItem[key].value = this.editedItem[key].type === 'number' ? 0 : ''
				}
			}
		},
		mapItemToEditedItem(item) {
			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key) && item.hasOwnProperty(key)) {
					this.editedItem[key].value = item[key]
				}
			}
		},
		async deleteItem(item) {
			console.dir(item)
			if (
				confirm(
					`この予定を削除しますか？\n\nid: ${item.lessonId}\n講師名: ${item.teacherName}\n生徒名: ${item.studentName}\n開始時刻: ${item.startTime}\n終了時刻: ${item.endTime}\nコース: ${item.course}`
				)
			) {
				try {
					await this.delete(item.lessonId)
				} catch (error) {
					console.log(error)
				}
				this.closeDelete()
				this.reload()
			}
		},
		async deleteAllSelectedLessons() {
			if (confirm('選択したレッスンを一括削除します。よろしいですか？')) {
				for (let selectedLesson of this.selectedLessons) {
					try {
						await this.delete(selectedLesson.lessonId)
					} catch (error) {
						console.log(error)
					}
				}
				this.selectedLessons = []
				this.closeDeleteDialog()
				this.reload()
			}
		},
		delete(lessonId) {
			return new Promise((resolve) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.userInfo.accessToken}`
					}
				}

				this.axios
					.delete(`/api/lessons/${lessonId}`, header)
					.then((response) => {
						console.log('response.data', response.data)
						resolve()
					})
					.catch((error) => {
						alert(error.response.data.error.message)
					})
			})
		},
		closeEditDialog() {
			this.showEditDialog = false
		},
		closeDelete() {
			this.dialogDelete = false
		},
		save() {
			const header = {
				headers: {
					Authorization: `Bearer ${this.userInfo.accessToken}`
				}
			}

			const payloadForEdit = Object.fromEntries(
				Object.entries(this.editedItem).map(([key, value]) => {
					return [key, value.value]
				})
			)
			console.log('payloadForEdit: ', payloadForEdit)

			this.axios
				.put(`/api/lessons/${this.selectedLessonId}`, payloadForEdit, header)
				.then(() => {
					this.closeEditDialog()
					this.reload()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		openAddDialog() {
			this.resetEditedForm()
			this.showAddDialog = true
		},
		openDeleteDialog() {
			this.showDeleteDialog = true
		},
		openBulkEditDialog() {
			this.showBulkEditDialog = true
		},
		closeAddDialog() {
			this.showAddDialog = false
		},
		closeDeleteDialog() {
			this.showDeleteDialog = false
		},
		closeBulkEditDialog() {
			this.showBulkEditDialog = false
		},
		addComplete() {
			this.showAddDialog = false
			this.reload()
		},
		prevPage() {
			if (this.currentPage > 1) {
				this.currentPage--
				this.setUpLessonData()
			}
		},
		nextPage() {
			if (this.currentPage < this.totalPages) {
				this.currentPage++
				this.setUpLessonData()
			}
		},
		async searchWithKeyword() {
			this.resetPage()
			this.setUpLessonData()
		},
		resetPage() {
			this.currentPage = 1
		},
		async bulkEdit(payload) {
			if (confirm('一括編集します。よろしいですか？')) {
				const selectedLessonIds = this.selectedLessons.map((v) => v.lessonId)
				await this.bulkEditLessons({
					accessToken: this.userInfo.accessToken,
					updatedLessonIds: selectedLessonIds,
					payload
				})

				this.closeBulkEditDialog()
				this.reload()
			}
		},
		resetSort() {
			this.sortBy = []
			this.sortDesc = false

			Object.keys(this.filterItems).forEach((key) => {
				this.filterItems[key] = ''
			})
		}
	}
}
</script>

<style lang="scss" scoped>
v-data-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	background-color: #dcc268;
}

thead.v-data-table-header {
	background-color: #dcc268;
}

.filter-area {
	padding: 0 20px;
}
.filter-item {
	padding: 0 5px;
}
.csv-button {
	margin-left: 15px;
}
.fromTodayButton {
	margin-right: 15px;
}
.sticky-table-header td {
	position: -webkit-sticky;
	position: sticky;
	top: 124px;
	background-color: white;
	/* tbody内のセルより手前に表示する */
	z-index: 2;
	height: 80px;

	@media (min-width: 1024px) {
		top: 80px;
	}
	@media (min-width: 1440px) {
		top: 60px;
	}
	@media (min-width: 1920px) {
		top: 40px;
	}
}
::v-deep .v-data-table-header {
	height: 180px;
	@media (min-width: 1920px) {
		height: 120px;
	}
}
.theme--light.v-data-table
	> .v-data-table__wrapper
	> table
	> tbody
	> tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
	background: white !important;
}
.tableFunctionArea {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 12px;
}
.expandHeader {
	display: flex;
	flex: 1;
	text-align: left;
	justify-content: start;
	margin-bottom: 20px;
}
.simpleButton {
	color: #dcc268;
}
.pagination {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}
.bulkEditButton {
	margin-right: 12px;
}
</style>
